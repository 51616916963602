<template>
  <div class="list">
    <div class="list_b">
      <ul>
      </ul>
    </div>
    <div class="home_list">
      <div class="list_d">
      <table>
        <tbody>
          <tr>
            <td style="text-align:center; padding:30px 5px">系统默认每天0点备份数据；后续会在此陆续开放用户独立备份到本地、用户自定义备份时间功能</td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'backSetting',
  data() {
    return {
      
    }
  },
  created() {},
  components: { },
  mounted() {},
  methods: {
    
  },
}
</script>
